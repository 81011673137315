import { useState, useRef, useEffect } from 'react';
import { Box, Stack, Typography, Button, CircularProgress, Collapse, useTheme, darken } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import WaveSurfer from 'wavesurfer.js';

import Test from '../../../model/Test';
import Operation from '../../../model/Operation';


interface AudioParameters {
    question: string | null;
}


function Audio({ question }: AudioParameters) {
    const [ operation, setOperation ] = useState<Operation<Blob | null>>(Operation.idle());
    const [ plays, setPlays ] = useState(0);
    const [ playing, setPlaying ] = useState(false);
    const containerReference = useRef<HTMLElement | null>(null);
    const waveSurferReference = useRef<WaveSurfer | null>(null);
    const theme = useTheme();
    const active = Test.questionIsAudible(question || '');
    const audio = operation.result;
    const limit = 2;
    const availablePlays = limit - plays;

    useEffect(() => {
        setOperation(Operation.idle());
        setPlays(0);
        setPlaying(false);

        if (active) {
            Test.getAudio(question!, setOperation);
        }
    }, [ question, active ]);

    useEffect(() => {
        if (audio) {
            const audioURL = URL.createObjectURL(audio);
            const waveSurfer = WaveSurfer.create({
                url: audioURL,
                container: containerReference.current!,
                height: 'auto',
                interact: false,
                hideScrollbar: true,
                barWidth: 2,
                barGap: 4,
                barRadius: 2,
                cursorWidth: 1,
                waveColor: '#4C4193',
                progressColor: '#5C539D',
                cursorColor: '#8F89BC'
            });

            waveSurfer.on('ready', () => { waveSurferReference.current = waveSurfer; });
            waveSurfer.on('play', () => setPlaying(true));
            waveSurfer.on('pause', () => setPlaying(false));

            return () => {
                URL.revokeObjectURL(audioURL);
                waveSurfer.destroy();
            }
        }
    }, [ audio ]);

    const play = () => {
        setPlays(previousPlays => previousPlays + 1);
        waveSurferReference.current!.play();
    }

    return (
        <Collapse in={active}>
            <Box sx={{
                position: 'relative',
                padding: 3,
                background: darken(theme.palette.secondary.main, 0.5)
            }}>
                <Box
                    ref={containerReference}
                    sx={{ height: 128 }}
                />

                <Box sx={{
                    zIndex: 10,
                    position: 'absolute',
                    top: '50%',
                    left: 24,
                    right: 24,
                    transform: 'translateY(-50%)'
                }}>
                    {operation.succeeded && (
                        <Stack alignItems="center">
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    color: 'white',
                                    textShadow: `0 1px 2px ${theme.palette.text.primary}`
                                }}>
                                {availablePlays > 0 ? (
                                    <>Puedes escuchar este audio {availablePlays === 1 ? 'una vez más' : `${availablePlays} veces`}.</>
                                ) : (
                                    <>Ya escuchaste este audio {limit} veces.</>
                                )}
                            </Typography>

                            <Collapse in={availablePlays > 0}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={play}
                                    disabled={playing}
                                    startIcon={<PlayArrow />}
                                    sx={{
                                        width: 256,
                                        marginTop: 3,
                                        '&:disabled': {
                                            color: theme.palette.text.primary,
                                            background: 'white',
                                            boxShadow: 2
                                        }
                                    }}>
                                    {playing ? 'Reproduciendo' : 'Reproducir'}
                                </Button>
                            </Collapse>
                        </Stack>
                    )}

                    {operation.failed && (
                        <Typography align="center" sx={{ color: 'white' }}>
                            Ocurrió un error cargando este audio. Por favor intentalo de nuevo más tarde.
                        </Typography>
                    )}

                    {operation.loading && (
                        <CircularProgress sx={{
                            display: 'block',
                            margin: '0 auto',
                            color: 'white'
                        }} />
                    )}
                </Box>
            </Box>
        </Collapse>
    );
}


export default Audio;
export type { AudioParameters };