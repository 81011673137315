import { useState, useRef, useEffect } from 'react';
import { Stack } from '@mui/material';

import User from '../../../model/User';
import Result from '../../../model/Result';

import Header from './Header';
import Typeform from './Typeform';
import Audio from './Audio';


interface TestParameters {
    user: User;
    exit?: () => void;
    cancel?: () => void;
    onStart?: () => void;
}


function Test({ user, exit, cancel, onStart }: TestParameters) {
    const [ question, setQuestion ] = useState<string | null>(null);
    const [ time, setTime ] = useState(45 * 60);
    const [ ended, setEnded ] = useState(false);
    const timerReference = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (time === 0) {
            clearInterval(timerReference.current!);
        }
    }, [ time ]);

    const start = (ID: string) => {
        Result.setID(ID);

        if (timerReference.current) {
            clearInterval(timerReference.current);
        }

        timerReference.current = setInterval(() => {
            setTime(previousTime => previousTime - 1);
        }, 1000);

        if (onStart) {
            onStart();
        }
    }

    const end = () => {
        Result.setID(null);

        setQuestion(null);
        setEnded(true);
        clearInterval(timerReference.current!);
    }

    return (
        <Stack sx={{ height: '100vh' }}>
            <Header
                time={time}
                ended={ended}
                exit={exit}
                cancel={cancel}
            />

            <Typeform
                user={user}
                onStart={start}
                onQuestionChange={setQuestion}
                onEnd={end}
            />

            <Audio question={question} />
        </Stack>
    );
}


export default Test;
export type { TestParameters };