import { ButtonProps, Button, alpha } from '@mui/material';


function HeaderButton(parameters: ButtonProps) {
    return (
        <Button
            sx={{
                width: 128,
                color: 'white',
                background: alpha('#FFFFFF', 0.08),
                border: `1px solid ${alpha('#FFFFFF', 0.24)}`,
                '&:hover': {
                    background: alpha('#FFFFFF', 0.08),
                    border: `1px solid ${alpha('#FFFFFF', 0.64)}`
                }
            }}
            {...parameters}
        />
    );
}


export default HeaderButton;