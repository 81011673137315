import { useState } from 'react';
import { Stack, Typography, Button, Dialog, useTheme, useMediaQuery, alpha } from '@mui/material';

import User from '../../model/User';
import Result from '../../model/Result';

import Test from './Test/Test';


interface StatusParameters {
    user: User;
}


function Status({ user }: StatusParameters) {
    const [ retakingExam, setRetakingExam ] = useState(false);
    const theme = useTheme();
    const wide = useMediaQuery(theme.breakpoints.up('md'));
    const { title, description } = (() => {
        if (user.beginner) {
            return {
                title: 'Estás en nivel 1.',
                description: 'Uno de nuestros ejecutivos se comunicará contigo pronto para hablar de tus siguientes pasos.'
            };
        } else if (user.tests === 1) {
            return {
                title: 'Estamos evaluando tus resultados.',
                description: 'Uno de nuestros ejecutivos se comunicará contigo pronto para darte tus resultados.'
            };
        } else {
            return {
                title: `Haz tomado el examen ${user.tests} veces.`,
                description: 'Uno de nuestros ejecutivos se comunicará contigo pronto para darte tus resultados.'
            };
        }
    })();

    return (
        <>
            <Stack spacing={6} sx={{ width: '100%', maxWidth: 600 }}>
                <Stack spacing={3}>
                    <Typography variant="h2" align="center">
                        {title}
                    </Typography>
                    <Typography
                        fontSize={{ md: 18, lg: 20 }}
                        align="center"
                        color="text.secondary">
                        {description}
                    </Typography>
                </Stack>

                {!user.beginner && (
                    <Stack spacing={1.5}>
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={3}
                            sx={{
                                padding: 3,
                                background: alpha(theme.palette.primary.main, 0.08),
                                borderRadius: 1
                            }}>
                            <Stack spacing={0.5} sx={{ flex: 1 }}>
                                <Typography variant="h4">
                                    ¿Quieres tomar el examen de nuevo?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Necesitarás 45 minutos sin interrupción.
                                </Typography>
                            </Stack>

                            <Button
                                variant="outlined"
                                size={wide ? 'medium' : 'small'}
                                onClick={() => setRetakingExam(true)}
                                sx={{
                                    alignSelf: { xs: 'start', md: 'center' }
                                }}>
                                Retomar Examen
                            </Button>
                        </Stack>

                        {Result.ID && (
                            <Typography variant="body2" align="center" color="error">
                                (Tienes respuestas guardadas)
                            </Typography>
                        )}
                    </Stack>
                )}
            </Stack>

            <Dialog
                fullScreen
                open={retakingExam}
                onClose={() => setRetakingExam(false)}
                PaperProps={{
                    sx: {
                        background: theme.palette.background.default
                    }
                }}>
                <Test
                    user={user}
                    cancel={() => setRetakingExam(false)}
                />
            </Dialog>
        </>
    );
}


export default Status;
export type { StatusParameters };