import { useState } from 'react';
import { Stack, TextField, Divider } from '@mui/material';

import Result from '../../../model/Result';

import Section from './Section';
import AnswerSets from './AnswerSets';


interface FocusedResultParameters {
    result: Result | null;
}


function FocusedResult({ result }: FocusedResultParameters) {
    const [ stateResult ] = useState(result!);

    return (
        <Stack divider={<Divider />}>
            <Section title="Información">
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                        <TextField label="Nombre" value={stateResult.firstName || '—'} />
                        <TextField label="Apellido" value={stateResult.lastName || '—'} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <TextField label="Código" value={stateResult.code || '—'} />
                        <TextField label="Correo" value={stateResult.email || '—'} />
                        <TextField label="Teléfono" value={stateResult.phone || '—'} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <TextField label="Empresa" value={stateResult.company || '—'} />
                        <TextField label="Cargo" value={stateResult.workTitle || '—'} />
                        <TextField label="Correo Corporativo" value={stateResult.workEmail || '—'} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <TextField label="País" value={stateResult.country || '—'} />
                        <TextField label="Ciudad" value={stateResult.city || '—'} />
                        <TextField label="Dirección" value={stateResult.address || '—'} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <TextField label="Ejecutivo" value={stateResult.partner || '—'} />
                        <TextField label="Duración" value={stateResult.duration || '—'} />
                        <TextField label="Fecha" value={stateResult.submissionDate || '—'} />
                    </Stack>
                </Stack>
            </Section>

            <Section title="Examen">
                <Stack spacing={6}>
                    <AnswerSets
                        title="Comprensión Auditiva"
                        answers={[ stateResult.listeningAnswerSets ]}
                    />
                    <AnswerSets
                        title="Comprensión Escrita"
                        answers={[ stateResult.readingAnswerSets ]}
                    />
                    <AnswerSets
                        title="Total"
                        answers={[ stateResult.listeningAnswerSets, stateResult.readingAnswerSets ]}
                    />
                </Stack>
            </Section>
        </Stack>
    );
}


export default FocusedResult;
export type { FocusedResultParameters };