import { useState } from 'react';
import { Stack, Typography, Button, Dialog } from '@mui/material';

import Container from '../other/Container';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';


interface IntroductionParameters {
    setUserID: (ID: string | null) => void;
}


function Introduction({ setUserID }: IntroductionParameters) {
    const [ signingUp, setSigningUp ] = useState(false);
    const [ signingIn, setSigningIn ] = useState(false);

    return (
        <>
            <Container>
                <Stack spacing={6} sx={{ maxWidth: 600 }}>
                    <Stack spacing={3}>
                        <Typography
                            variant="h1"
                            align="center">
                            ¿Quieres conocer tu nivel de Inglés?
                        </Typography>
                        <Typography
                            fontSize={{ md: 18, lg: 20 }}
                            align="center"
                            color="text.secondary">
                            Realiza nuestro examen de clasificación para saber en qué nivel debes iniciar tu programa de inglés conversacional y de negocios.
                        </Typography>
                    </Stack>

                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent="center"
                        spacing={{ xs: 1, md: 3 }}
                        sx={{
                            alignSelf: 'center',
                            width: '100%',
                            maxWidth: { xs: 320, md: 480 }
                        }}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={() => setSigningUp(true)}>
                            Registrarse
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={() => setSigningIn(true)}>
                            Iniciar Sesión
                        </Button>
                    </Stack>
                </Stack>
            </Container>

            <Dialog open={signingUp} onClose={() => setSigningUp(false)}>
                <SignUpForm setUserID={setUserID} />
            </Dialog>
            <Dialog open={signingIn} onClose={() => setSigningIn(false)}>
                <SignInForm setUserID={setUserID} />
            </Dialog>
        </>
    );
}


export default Introduction;
export type { IntroductionParameters };