import { useState } from 'react';
import { Stack, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Result from '../../model/Result';
import Operation from '../../model/Operation';

import Container from '../other/Container';
import FormOperation from '../other/FormOperation';


interface GateParameters {
    setKey: (key: string) => void;
}


function Gate({ setKey }: GateParameters) {
    const [ password, setPassword ] = useState('');
    const [ operation, setOperation ] = useState<Operation<string | null>>(Operation.idle());

    const enter = () => {
        if (password) {
            Result.getSearchKey(password, operation => {
                setOperation(operation);

                if (operation.succeeded) {
                    setKey(operation.result!);
                }
            });
        }
    }

    return (
        <Container>
            <Stack spacing={5} sx={{ width: '100%', maxWidth: 600 }}>
                <Typography variant="h2" align="center">
                    ¿Cuál es la palabra mágica?
                </Typography>

                <Stack
                    spacing={6}
                    sx={{
                        alignSelf: 'center',
                        width: '100%',
                        maxWidth: 352
                    }}>
                    <TextField
                        label="Contraseña"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        onKeyDown={event => event.key === 'Enter' && enter()}
                        disabled={operation.loading}
                    />

                    <div>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            onClick={enter}
                            loading={operation.loading}
                            disabled={!password}>
                            Entrar
                        </LoadingButton>
                        <FormOperation operation={operation} />
                    </div>
                </Stack>
            </Stack>
        </Container>
    );
}


export default Gate;
export type { GateParameters };