import { useState } from 'react';
import { Box, Stack, Typography, TextField, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import User from '../../model/User';
import Operation from '../../model/Operation';
import { cleanSpaces, validateEmail } from '../../model/supportMethods';

import FormOperation from '../other/FormOperation';


interface SignUpFormParameters {
    setUserID: (ID: string) => void;
}


function SignUpForm({ setUserID }: SignUpFormParameters) {
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ code, setCode ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ company, setCompany ] = useState(''); // optional
    const [ workTitle, setWorkTitle ] = useState(''); // optional
    const [ workEmail, setWorkEmail ] = useState(''); // optional
    const [ country, setCountry ] = useState('');
    const [ city, setCity ] = useState('');
    const [ address, setAddress ] = useState(''); // optional
    const [ partner, setPartner ] = useState('');
    const [ operation, setOperation ] = useState<Operation<string | null>>(Operation.idle());
    const cleanFirstName = cleanSpaces(firstName);
    const cleanLastName = cleanSpaces(lastName);
    const cleanCode = cleanSpaces(code);
    const cleanEmail = cleanSpaces(email);
    const validEmail = validateEmail(email);
    const cleanPhone = cleanSpaces(phone);
    const cleanCompany = cleanSpaces(company);
    const cleanWorkTitle = cleanSpaces(workTitle);
    const cleanWorkEmail = cleanSpaces(workEmail);
    const validWorkEmail = validateEmail(workEmail);
    const cleanCountry = cleanSpaces(country);
    const cleanCity = cleanSpaces(city);
    const cleanAddress = cleanSpaces(address);
    const disabled = !cleanFirstName || !cleanLastName || !cleanCode || !cleanEmail || !validEmail || !cleanPhone || (cleanWorkEmail && !validWorkEmail) || !cleanCountry || !cleanCity || !partner;

    const signUp = () => {
        const formattedPartner = partner === '—' ? '' : partner;

        User.signUp(
            cleanFirstName,
            cleanLastName,
            cleanCode,
            cleanEmail,
            cleanPhone,
            cleanCompany,
            cleanWorkTitle,
            cleanWorkEmail,
            cleanCountry,
            cleanCity,
            cleanAddress,
            formattedPartner,
            operation => {
                setOperation(operation);

                if (operation.succeeded) {
                    setUserID(operation.result!);
                }
            }
        );
    }

    return (
        <Box sx={{
            paddingX: { xs: 3, sm: 6 },
            paddingY: 6
        }}>
            <Stack spacing={1}>
                <Typography variant="h3" align="center">
                    Regístrate
                </Typography>
                <Typography variant="body2" align="center" color="text.secondary">
                    ¿Es tu primera vez aquí? Ingresa los siguientes datos para comenzar.
                </Typography>
            </Stack>

            <Stack spacing={3} sx={{ marginTop: 5, marginBottom: 6 }}>
                <TextField
                    label="Nombre*"
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Apellido*"
                    value={lastName}
                    onChange={event => setLastName(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Número de Identificación*"
                    value={code}
                    onChange={event => setCode(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Correo Electrónico*"
                    value={email}
                    error={!!email && !validEmail}
                    helperText={(!!email && !validEmail) ? 'Este correo no parece ser valido.' : undefined}
                    onChange={event => setEmail(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Teléfono*"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Empresa (opcional)"
                    helperText="Si perteneces a un convenio, indica la empresa."
                    value={company}
                    onChange={event => setCompany(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Cargo (opcional)"
                    value={workTitle}
                    onChange={event => setWorkTitle(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Correo Corporativo (opcional)"
                    value={workEmail}
                    error={!!workEmail && !validWorkEmail}
                    helperText={(!!workEmail && !validWorkEmail) ? 'Este correo no parece ser valido.' : undefined}
                    onChange={event => setWorkEmail(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="País*"
                    value={country}
                    onChange={event => setCountry(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Ciudad*"
                    value={city}
                    onChange={event => setCity(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    label="Dirección (opcional)"
                    value={address}
                    onChange={event => setAddress(event.target.value)}
                    disabled={operation.loading}
                />
                <TextField
                    select
                    label="Ejecutivo que te atendió*"
                    value={partner}
                    onChange={event => setPartner(event.target.value)}
                    disabled={operation.loading}>
                    {Object.keys(User.partners).map(key => (
                        <MenuItem key={key} value={key}>
                            {User.partners[key]}
                        </MenuItem>
                    ))}
                    <MenuItem value="—">
                        Ninguno / No Recuerdo
                    </MenuItem>
                </TextField>
            </Stack>

            <div>
                <Stack spacing={1}>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        onClick={signUp}
                        loading={operation.loading}
                        disabled={disabled}>
                        Registrarse
                    </LoadingButton>

                    {disabled && (
                        <Typography variant="body2" align="center" color="text.secondary">
                            Completa todos los campos obligatorios para registrarte.
                        </Typography>
                    )}
                </Stack>
                <FormOperation operation={operation} />
            </div>
        </Box>
    );
}


export default SignUpForm;
export type { SignUpFormParameters };