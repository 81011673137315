import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, createTheme, alpha } from '@mui/material';

import Firebase from '../model/Firebase';
import User from '../model/User';
import Operation from '../model/Operation';

import Introduction from './Introduction/Introduction';
import Dashboard from './Dashboard/Dashboard';
import Results from './Results/Results';
import ScrollResetter from './other/ScrollResetter';

import 'moment/locale/es';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';


function Root() {
    const [ theme ] = useState(() => {
        const smallBreakpoint = 400;
        const mediumBreakpoint = 800;
        const largeBreakpoint = 1200;
        const extraLargeBreakpoint = 1600;

        const primaryColor = '#3A5EFF';
        const secondaryColor = '#553AFF';
        const errorColor = '#D61A67';
        const primaryTextColor = '#203152';
        const secondaryTextColor = '#7E8DA6';
        const backgroundGrayColor = '#F1F3F6';
        const borderGrayColor = '#E0E3E9';

        const softShadow = `0 2px 8px ${alpha(primaryTextColor, 0.24)}`;
        const hardShadow = `0 2px 64px ${alpha(primaryTextColor, 0.4)}`;

        return createTheme({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: smallBreakpoint,
                    md: mediumBreakpoint,
                    lg: largeBreakpoint,
                    xl: extraLargeBreakpoint
                }
            },
            palette: {
                primary: {
                    main: primaryColor
                },
                secondary: {
                    main: secondaryColor
                },
                error: {
                    main: errorColor
                },
                text: {
                    primary: primaryTextColor,
                    secondary: secondaryTextColor
                },
                action: {
                    hover: backgroundGrayColor,
                    disabled: secondaryTextColor,
                    disabledBackground: backgroundGrayColor
                },
                background: {
                    default: secondaryColor
                },
                divider: borderGrayColor
            },
            typography: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 16,
                h1: {
                    fontSize: 24,
                    fontWeight: 500,
                    [`@media (min-width: ${smallBreakpoint}px)`]: {
                        fontSize: 32
                    },
                    [`@media (min-width: ${mediumBreakpoint}px)`]: {
                        fontSize: 40
                    }
                },
                h2: {
                    fontSize: 24,
                    fontWeight: 500,
                    [`@media (min-width: ${mediumBreakpoint}px)`]: {
                        fontSize: 32
                    }
                },
                h3: {
                    fontSize: 24,
                    fontWeight: 500
                },
                h4: {
                    fontSize: 16,
                    fontWeight: 500
                },
                body1: {
                    fontSize: 16,
                    fontWeight: 400
                },
                body2: {
                    fontSize: 14,
                    fontWeight: 500
                },
                button: {
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: 'none',
                    lineHeight: 'normal'
                }
            },
            shape: {
                borderRadius: 8
            },
            shadows: [
                'none', softShadow, hardShadow, 'none', 'none',
                'none', 'none', 'none', 'none', 'none',
                'none', 'none', 'none', 'none', 'none',
                'none', 'none', 'none', 'none', 'none',
                'none', 'none', 'none', 'none', 'none'
            ],
            transitions: {
                duration: {
                    standard: 200
                }
            },
            components: {
                MuiSvgIcon: {
                    styleOverrides: {
                        fontSizeMedium: {
                            fontSize: 24
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: 16,
                            borderRadius: '100px'
                        },
                        contained: {
                            boxShadow: softShadow,
                            '&:hover': {
                                boxShadow: softShadow
                            }
                        },
                        sizeSmall: {
                            padding: '6px 16px',
                        },
                        sizeMedium: {
                            minHeight: 40,
                            padding: '8px 24px'
                        },
                        sizeLarge: {
                            padding: '12px 48px'
                        }
                    }
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            '&:before': {
                                borderBottom: `1px solid ${borderGrayColor}`
                            }
                        }
                    }
                },
                MuiTextField: {
                    defaultProps: {
                        fullWidth: true,
                        variant: 'standard'
                    }
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            fontSize: 12
                        }
                    }
                },
                MuiSelect: {
                    defaultProps: {
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    marginTop: 1
                                }
                            }
                        }
                    }
                },
                MuiPopover: {
                    defaultProps: {
                        elevation: 1,
                        BackdropProps: {
                            sx: {
                                background: alpha(primaryTextColor, 0.24)
                            }
                        }
                    }
                },
                MuiDialog: {
                    defaultProps: {
                        fullWidth: true,
                        scroll: 'body',
                        PaperProps: {
                            elevation: 2
                        },
                        slotProps: {
                            backdrop: {
                                sx: {
                                    background: alpha(primaryTextColor, 0.64)
                                }
                            }
                        }
                    }
                }
            }
        });
    });
    const [ userID, setUserID ] = useState(User.ID);
    const [ userOperation, setUserOperation ] = useState<Operation<User | null>>(Operation.idle());

    useEffect(() => {
        Firebase.initialize();
    }, []);

    useEffect(() => {
        User.setID(userID);

        if (userID) {
            return User.observe(userID, setUserOperation);
        } else {
            setUserOperation(Operation.idle());
        }
    }, [ userID ]);

    return (
        <BrowserRouter>
            <ScrollResetter />

            <ThemeProvider theme={theme}>
                <CssBaseline />

                <Routes>
                    <Route index element={userID ? (
                        <Dashboard
                            userOperation={userOperation}
                            setUserID={setUserID}
                        />
                    ) : (
                        <Introduction setUserID={setUserID} />
                    )} />
                    <Route path="/results" element={(
                        <Results />
                    )} />
                    <Route path="*" element={(
                        <Navigate to="/" />
                    )} />
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    );
}


export default Root;