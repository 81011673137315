import { StorageReference, getStorage, ref, getBlob } from 'firebase/storage';

import Operation, { OperationUpdate } from './Operation';


class Test {

    static get storageReference(): StorageReference {
        return ref(getStorage(), 'test');
    }

    static questionIsAudible = (question: string): boolean => {
        return question.startsWith('L');
    }

    static getAudio = (question: string, update: OperationUpdate<Blob>): void => {
        update(Operation.load());

        getBlob(ref(Test.storageReference, `audio/${question.substring(1)}.mp3`))
            .then(blob => update(Operation.succeed(blob)))
            .catch(() => update(Operation.fail()));
    }

}


export default Test;