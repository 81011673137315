import { ReactNode } from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';

import Logo from '../../assets/Logo.png';


interface HeaderParameters {
    leftSideInterface?: ReactNode;
    rightSideInterface?: ReactNode;
}


function Header({ leftSideInterface, rightSideInterface }: HeaderParameters) {
    const wide = useMediaQuery('@media (min-width: 600px)');
    const showLogo = (leftSideInterface && rightSideInterface) ? wide : true;

    return (
        <Box sx={{ paddingX: 1 }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={(leftSideInterface || rightSideInterface) ? 'space-between' : 'center'}
                spacing={3}
                sx={{
                    height: 64,
                    paddingX: 3
                }}>
                {leftSideInterface}

                {showLogo && (
                    <img
                        src={Logo}
                        alt="Logo de Coninglés"
                        style={{ height: 32 }}
                    />
                )}

                {rightSideInterface}
            </Stack>
        </Box>
    );
}


export default Header;
export type { HeaderParameters };