import { Stack, Typography, CircularProgress } from '@mui/material';

import Operation, { OperationError } from '../../model/Operation';


interface ContainerOperationParameters<T> {
    operation: Operation<T>;
}


function ContainerOperation<T>({ operation }: ContainerOperationParameters<T>) {
    if (operation.failed) {
        return (
            <Stack spacing={3} sx={{ maxWidth: 600 }}>
                <Typography variant="h2" align="center">
                    Ocurrió un Error
                </Typography>
                <Typography align="center" color="text.secondary">
                    {operation.error === OperationError.unknown ? 'Por favor inténtalo de nuevo más tarde.' : operation.errorMessage}
                </Typography>
            </Stack>
        );
    } else {
        return (
            <CircularProgress sx={{ display: 'block' }} />
        );
    }
}


export default ContainerOperation;
export type { ContainerOperationParameters };