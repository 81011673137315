import { useState } from 'react';
import { Box, Stack, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import User from '../../model/User';
import Operation from '../../model/Operation';
import { cleanSpaces } from '../../model/supportMethods';

import FormOperation from '../other/FormOperation';


interface SignInFormParameters {
    setUserID: (ID: string) => void;
}


function SignInForm({ setUserID }: SignInFormParameters) {
    const [ code, setCode ] = useState('');
    const [ operation, setOperation ] = useState<Operation<string | null>>(Operation.idle());
    const cleanCode = cleanSpaces(code);
    const disabled = !cleanCode;

    const signIn = () => {
        User.signIn(cleanCode, operation => {
            setOperation(operation);

            if (operation.succeeded) {
                setUserID(operation.result!);
            }
        });
    }

    return (
        <Box sx={{
            paddingX: { xs: 3, sm: 6 },
            paddingY: 6
        }}>
            <Stack spacing={1}>
                <Typography variant="h3" align="center">
                    Inicia Sesión
                </Typography>
                <Typography variant="body2" align="center" color="text.secondary">
                    ¿Ya te registraste? Administra tu examen con tu número de identificación.
                </Typography>
            </Stack>

            <TextField
                label="Número de Identificación"
                value={code}
                onChange={event => setCode(event.target.value)}
                disabled={operation.loading}
                sx={{ marginTop: 5, marginBottom: 6 }}
            />

            <div>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={signIn}
                    loading={operation.loading}
                    disabled={disabled}>
                    Iniciar Sesión
                </LoadingButton>
                <FormOperation operation={operation} />
            </div>
        </Box>
    );
}


export default SignInForm;
export type { SignInFormParameters };