import { initializeApp } from 'firebase/app';


class Firebase {

    static initialize = () => {
        initializeApp({
            apiKey: 'AIzaSyAoPbcMNiYPL-NMErM_lzAowO6sp0o7Nvg',
            authDomain: 'placement-test-4d836.firebaseapp.com',
            projectId: 'placement-test-4d836',
            storageBucket: 'placement-test-4d836.appspot.com',
            messagingSenderId: '381706991935',
            appId: '1:381706991935:web:ff2033266a05186ca23b8a'
        });
    }

}


export default Firebase;