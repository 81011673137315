type OperationUpdate<T = null> = (operation: Operation<T | null>) => void;

enum OperationStatus {
    idle = 'idle',
    loading = 'loading',
    succeeded = 'succeeded',
    failed = 'failed'
}

enum OperationError {
    userAlreadyExists = 'userAlreadyExists',
    userDoesNotExist = 'userDoesNotExist',
    passwordIsIncorrect = 'passwordIsIncorrect',
    unknown = 'unknown'
}


class Operation<T = null> {

    static idle = (): Operation => {
        return new Operation(OperationStatus.idle);
    }

    static load = (progress?: number): Operation => {
        const operation = new Operation(OperationStatus.loading);

        operation.progress = progress || null;

        return operation;
    }

    static succeed = <T>(result?: T): Operation<T> => {
        const operation = new Operation<T>(OperationStatus.succeeded);

        operation.result = result !== undefined ? result : null;

        return operation;
    }

    static fail = (error?: OperationError): Operation => {
        const operation = new Operation(OperationStatus.failed);

        operation.error = error || OperationError.unknown;

        return operation;
    }


    status: OperationStatus;
    progress: number | null;
    result: T | null;
    error: OperationError | null;

    constructor(status: OperationStatus) {
        this.status = status;
        this.progress = null;
        this.result = null;
        this.error = null;
    }


    get idle(): boolean {
        return this.status === OperationStatus.idle;
    }
    get loading(): boolean {
        return this.status === OperationStatus.loading;
    }
    get succeeded(): boolean {
        return this.status === OperationStatus.succeeded;
    }
    get failed(): boolean {
        return this.status === OperationStatus.failed;
    }
    get finished(): boolean {
        return this.succeeded || this.failed;
    }
    get errorMessage(): string | null {
        if (this.failed) {
            return {
                [OperationError.userAlreadyExists]: 'Ya hay un usuario registrado con este número de identificación.',
                [OperationError.userDoesNotExist]: 'No hay ningún usuario registrado con este número de identificación.',
                [OperationError.passwordIsIncorrect]: 'Esta contraseña es incorrecta.',
                [OperationError.unknown]: 'Ha ocurrido un error inesperado. Por favor inténtalo de nuevo más tarde.'
            }[this.error!];
        } else {
            return null;
        }
    }

}


export default Operation;
export type { OperationUpdate };
export { OperationStatus, OperationError };