import { Stack, Typography, useTheme, alpha } from '@mui/material';
import { TimerOutlined } from '@mui/icons-material';

import SharedHeader from '../../other/Header';
import HeaderButton from '../../other/HeaderButton';


interface HeaderParameters {
    time: number;
    ended: boolean;
    exit?: () => void;
    cancel?: () => void;
}


function Header({ time, ended, exit, cancel }: HeaderParameters) {
    const theme = useTheme();
    const formattedTime = (() => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    })();
    const timeWarning = time <= 5 * 60;

    return (
        <SharedHeader
            leftSideInterface={(
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    sx={{
                        width: 128,
                        height: 40,
                        background: timeWarning ? theme.palette.error.main : alpha('#FFFFFF', 0.08),
                        borderRadius: '100px',
                        boxShadow: timeWarning ? 1 : 0,
                        transition: theme.transitions.create([ 'background', 'box-shadow' ])
                    }}>
                    <TimerOutlined sx={{ color: 'white' }} />

                    <Typography variant="h4" sx={{ color: 'white' }}>
                        {formattedTime}
                    </Typography>
                </Stack>
            )}
            rightSideInterface={(
                <>
                    {exit && (
                        <HeaderButton onClick={exit}>
                            Salir
                        </HeaderButton>
                    )}

                    {cancel && (
                        <HeaderButton onClick={cancel}>
                            {ended ? 'Salir' : 'Cancelar'}
                        </HeaderButton>
                    )}
                </>
            )}
        />
    );
}


export default Header;
export type { HeaderParameters };