import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';


interface SectionParameters {
    title: string;
    children: ReactNode;
}


function Section({ title, children }: SectionParameters) {
    return (
        <Stack spacing={6} sx={{ padding: 6 }}>
            <Typography variant="h3" >
                {title}
            </Typography>

            {children}
        </Stack>
    );
}


export default Section;
export type { SectionParameters };