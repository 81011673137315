interface LocalStorageGetOptions<T> {
    defaultValue?: T;
    possibleValues?: T[];
    validateValue?: (value: unknown) => boolean
}


class LocalStorage {

    static keyBase: string = 'placementTest';

    static getValue = <T>(key: string, options?: LocalStorageGetOptions<T>): T | null => {
        const { defaultValue, possibleValues, validateValue } = options || {};
        const unparsedValue = localStorage.getItem(`${LocalStorage.keyBase}.${key}`);
        const value = unparsedValue ? JSON.parse(unparsedValue) : null;
        const valueIsValid = (() => {
            if (validateValue) {
                return validateValue(value);
            } else if (possibleValues) {
                return possibleValues.includes(value);
            } else {
                return true;
            }
        })();

        if (value !== null && valueIsValid) {
            return value;
        }

        return defaultValue || null;
    }

    static setValue = <T>(key: string, value: T): void => {
        localStorage.setItem(`${LocalStorage.keyBase}.${key}`, JSON.stringify(value));
    }

    static deleteValue = (key: string): void => {
        localStorage.removeItem(`${LocalStorage.keyBase}.${key}`);
    }

}


export default LocalStorage;
export type { LocalStorageGetOptions };