import { useState, useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Widget } from '@typeform/embed-react';

import User from '../../../model/User';


interface TypeformParameters {
    user: User;
    onStart: (ID: string) => void;
    onQuestionChange: (question: string) => void;
    onEnd: () => void;
}


function Typeform({ user, onStart, onQuestionChange, onEnd }: TypeformParameters) {
    const [ ready, setReady ] = useState(false);
    const widget = useMemo(() => (
        <Widget
            autoFocus
            inlineOnMobile
            disableScroll
            id="ecFH1UwB"
            hidden={{ user: user.ID }}
            onReady={() => setReady(true)}
            onStarted={event => onStart(event.responseId)}
            onQuestionChanged={question => onQuestionChange(question.ref)}
            onSubmit={onEnd}
            style={{ height: '100%' }}
        />
    ), [ user.ID ]);

    return (
        <Box sx={{
            flex: 1,
            position: 'relative',
            paddingX: 1,
            paddingBottom: 1
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 8,
                right: 8,
                bottom: 8,
                background: 'white',
                borderRadius: 1,
                boxShadow: 1
            }}>
                {!ready && (
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <CircularProgress sx={{ display: 'block' }} />
                    </Box>
                )}
            </Box>

            {widget}
        </Box>
    );
}


export default Typeform;
export type { TypeformParameters };