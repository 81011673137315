import { Box, Stack, Typography, useTheme, alpha } from '@mui/material';

import { ResultAnswerSet } from '../../../model/Result';


interface AnswerSetsParameters {
    title: string;
    answers: ResultAnswerSet[][];
}


function AnswerSets({ title, answers }: AnswerSetsParameters) {
    const theme = useTheme();
    const combinedSets = (() => {
        const combinedSets = [];

        for (let index = 0; index < 6; index++) {
            const indexSets = answers.map(sets => sets[index]!);
            const combinedSet = [];

            for (const set of indexSets) {
                combinedSet.push(...set);
            }

            combinedSets.push(combinedSet);
        }

        return combinedSets;
    })();
    const totalAnswers = combinedSets.map(set => set.length).reduce((C, L) => C + L, 0);
    const correctAnswers = combinedSets.map(set => set.filter(answer => answer).length).reduce((C, L) => C + L, 0);

    return (
        <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Typography variant="h4">
                    {title}
                </Typography>

                <Typography variant="h4" color="primary">
                    {correctAnswers} / {totalAnswers}
                </Typography>
            </Stack>

            <Stack direction="row" spacing={1}>
                {combinedSets.map((set, index) => (
                    <Stack
                        key={index}
                        spacing={1}
                        sx={{
                            flex: 1,
                            padding: 3,
                            background: alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                        }}>
                        <Typography align="center">
                            {set.filter(answer => answer).length}
                        </Typography>
                        <Box sx={{
                            height: '1px',
                            background: theme.palette.primary.main
                        }} />
                        <Typography align="center">
                            {set.length}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
}


export default AnswerSets;
export type { AnswerSetsParameters };