import { getFunctions, httpsCallable } from 'firebase/functions';
import Algolia from 'algoliasearch';
import Moment from 'moment';

import User from './User';
import LocalStorage from './LocalStorage';
import Operation, { OperationError, OperationUpdate } from './Operation';
import { capitalize } from './supportMethods';


type ResultAnswerSet = boolean[];

interface ResultMap {
    objectID: string;
    user: string;
    firstName: string;
    lastName: string;
    code: string;
    email: string;
    phone: string;
    company: string;
    workTitle: string;
    workEmail: string;
    country: string;
    city: string;
    address: string;
    partner: string;
    landingTime: number;
    submissionTime: number;
    answers: ResultAnswerSet;
}


class Result {

    static IDStorageKey = 'result.ID';

    static get ID(): string | null {
        return LocalStorage.getValue(Result.IDStorageKey);
    }

    static getSearchKey = (password: string, update: OperationUpdate<string>): void => {
        update(Operation.load());

        httpsCallable(getFunctions(), 'getResultsSearchKey')({ password })
            .then(result => {
                const key = result.data as string;

                update(Operation.succeed(key));
            })
            .catch(error => {
                if (error.code === 'functions/permission-denied') {
                    update(Operation.fail(OperationError.passwordIsIncorrect));
                } else {
                    update(Operation.fail());
                }
            });
    }

    static search = (key: string, query: string, update: OperationUpdate<Result[]>): void => {
        update(Operation.load());

        const algolia = Algolia('IARSG6A67Q', key);
        const index = algolia.initIndex('Results');

        index.search(query)
            .then(result => {
                const results = result.hits.map(hit => new Result(hit as ResultMap));

                update(Operation.succeed(results));
            })
            .catch(() => update(Operation.fail()));
    }

    static setID = (ID: string | null): void => {
        if (ID) {
            LocalStorage.setValue(Result.IDStorageKey, ID);
        } else {
            LocalStorage.deleteValue(Result.IDStorageKey);
        }
    }


    ID: string;
    user: string;
    firstName: string;
    lastName: string;
    code: string;
    email: string;
    phone: string;
    company: string;
    workTitle: string;
    workEmail: string;
    country: string;
    city: string;
    address: string;
    partner: string;
    landingTime: number;
    submissionTime: number;
    answers: boolean[];

    constructor(map: ResultMap) {
        this.ID = map.objectID;
        this.user = map.user;
        this.firstName = map.firstName;
        this.lastName = map.lastName;
        this.code = map.code;
        this.email = map.email;
        this.phone = map.phone;
        this.company = map.company;
        this.workTitle = map.workTitle;
        this.workEmail = map.workEmail;
        this.country = map.country;
        this.city = map.city;
        this.address = map.address;
        this.partner = map.partner;
        this.landingTime = map.landingTime;
        this.submissionTime = map.submissionTime;
        this.answers = map.answers.concat(Array(100 - map.answers.length).fill(false));
    }


    get partnerName(): string | null {
        return User.partners[this.partner] || null;
    }
    get duration(): number {
        const duration = this.submissionTime - this.landingTime;

        return Math.floor(duration / 60);
    }
    get submissionDate(): string {
        const moment = Moment(this.submissionTime * 1000);

        if (moment.isSame(Moment(), 'day')) {
            return `Hoy, ${moment.format('h:mm A')}`;
        } else {
            return capitalize(moment.format('MMMM D, Y, h:mm A'));
        }
    }
    get listeningAnswerSets(): ResultAnswerSet[] {
        return [
            this.answers.slice(0, 10),
            this.answers.slice(10, 18),
            this.answers.slice(18, 26),
            this.answers.slice(26, 34),
            this.answers.slice(34, 42),
            this.answers.slice(42, 50)
        ];
    }
    get readingAnswerSets(): ResultAnswerSet[] {
        return [
            this.answers.slice(50, 60),
            this.answers.slice(60, 68),
            this.answers.slice(68, 76),
            this.answers.slice(76, 84),
            this.answers.slice(84, 92),
            this.answers.slice(92, 100)
        ];
    }

}


export default Result;
export type { ResultAnswerSet, ResultMap };