import { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

import Header from './Header';
import HeaderButton from './HeaderButton';


interface ContainerParameters {
    signOut?: () => void;
    children: ReactNode;
}


function Container({ signOut, children }: ContainerParameters) {
    return (
        <div>
            <Header rightSideInterface={signOut ? (
                <HeaderButton onClick={signOut}>
                    Salir
                </HeaderButton>
            ) : undefined} />

            <Box sx={{
                height: 'calc(100vh - 64px)',
                paddingX: 1,
                paddingBottom: 1
            }}>
                <Box sx={{
                    height: '100%',
                    background: 'white',
                    borderRadius: 1,
                    boxShadow: 1,
                    overflowY: 'scroll'
                }}>
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            minHeight: '100%',
                            paddingX: 3,
                            paddingY: 6
                        }}>
                        {children}
                    </Stack>
                </Box>
            </Box>
        </div>
    );
}


export default Container;
export type { ContainerParameters };