import { useState, useEffect } from 'react';
import { Box, Dialog } from '@mui/material';

import Result from '../../model/Result';
import Operation from '../../model/Operation';

import Header from '../other/Header';
import SearchField from './SearchField';
import Table from './Table';
import FocusedResult from './FocusedResult/FocusedResult';
import Gate from './Gate';


function Results() {
    const [ key, setKey ] = useState<string | null>(null);
    const [ query, setQuery ] = useState('');
    const [ operation, setOperation ] = useState<Operation<Result[] | null>>(Operation.load());
    const [ focusedResult, setFocusedResult ] = useState<Result | null>(null);

    useEffect(() => {
        if (key) {
            Result.search(key, query, setOperation);
        } else {
            setOperation(Operation.idle());
        }
    }, [ key, query ]);

    if (key) {
        return (
            <>
                <div>
                    <Header rightSideInterface={(
                        <SearchField
                            query={query}
                            setQuery={setQuery}
                            operation={operation}
                        />
                    )} />

                    <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
                        <Table
                            results={operation.result || []}
                            focusResult={setFocusedResult}
                        />
                    </Box>
                </div>

                <Dialog
                    maxWidth="md"
                    open={!!focusedResult}
                    onClose={() => setFocusedResult(null)}>
                    <FocusedResult result={focusedResult} />
                </Dialog>
            </>
        );
    } else {
        return (
            <Gate setKey={setKey}/>
        );
    }
}


export default Results;