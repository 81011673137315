import { useState } from 'react';

import User from '../../model/User';
import Operation from '../../model/Operation';

import Container from '../other/Container';
import Onboarding from './Onboarding/Onboarding';
import Status from './Status';
import Test from './Test/Test';
import ContainerOperation from '../other/ContainerOperation';


interface DashboardParameters {
    userOperation: Operation<User | null>;
    setUserID: (ID: string | null) => void;
}


function Dashboard({ userOperation, setUserID }: DashboardParameters) {
    const [ onTest, setOnTest ] = useState(false);

    if (userOperation.succeeded) {
        const user = userOperation.result!;

        if (user.onboarded) {
            if ((user.beginner || user.tests > 0) && !onTest) {
                return (
                    <Container signOut={() => setUserID(null)}>
                        <Status user={user} />
                    </Container>
                );
            } else {
                return (
                    <Test
                        user={user}
                        exit={() => setUserID(null)}
                        onStart={() => setOnTest(true)}
                    />
                );
            }
        } else {
            return (
                <Container signOut={() => setUserID(null)}>
                    <Onboarding user={user!} />
                </Container>
            );
        }
    } else {
        return (
            <Container signOut={() => setUserID(null)}>
                <ContainerOperation operation={userOperation} />
            </Container>
        );
    }
}


export default Dashboard;
export type { DashboardParameters };