function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function cleanSpaces(string: string): string {
    return string.replace(/\s+/g, ' ').trim();
}

const validateEmail = (email: string): boolean => {
    return cleanSpaces(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
};


export {
    capitalize,
    cleanSpaces,
    validateEmail
};