import { CollectionReference, DocumentReference, DocumentSnapshot, Unsubscribe, getFirestore, collection, doc, onSnapshot, addDoc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import LocalStorage from './LocalStorage';
import Operation, { OperationError, OperationUpdate } from './Operation';


interface UserMap {
    firstName: string;
    lastName: string;
    code: string;
    email: string;
    phone: string;
    company: string;
    workTitle: string;
    workEmail: string;
    country: string;
    city: string;
    address: string;
    partner: string;
    beginner: boolean | null;
    tests: number;
}


class User {

    static IDStorageKey = 'user.ID';
    static partners: { [key: string]: string } = {
        227068793: 'Erika García',
        32197573: 'Claudia Pinzón Vélez',
        377772428: 'Andrea Mora',
        230291776: 'Alejandra Trujillo'
    };

    static get collectionReference(): CollectionReference {
        return collection(getFirestore(), 'users');
    }
    static get ID(): string | null {
        return LocalStorage.getValue(User.IDStorageKey);
    }

    static signUp = (firstName: string, lastName: string, code: string, email: string, phone: string, company: string, workTitle: string, workEmail: string, country: string, city: string, address: string, partner: string, update: OperationUpdate<string>): void => {
        update(Operation.load());

        httpsCallable(getFunctions(), 'getUserID')({ code })
            .then(result => {
                const ID = result.data as string | null;

                if (ID === null) {
                    const map: UserMap = {
                        firstName: firstName,
                        lastName: lastName,
                        code: code,
                        email: email,
                        phone: phone,
                        company: company,
                        workTitle: workTitle,
                        workEmail: workEmail,
                        country: country,
                        city: city,
                        address: address,
                        partner: partner,
                        beginner: null,
                        tests: 0
                    };

                    addDoc(User.collectionReference, map)
                        .then(documentReference => {
                            const ID = documentReference.id;

                            update(Operation.succeed(ID));
                        })
                        .catch(() => update(Operation.fail()));
                } else {
                    update(Operation.fail(OperationError.userAlreadyExists));
                }
            })
            .catch(() => update(Operation.fail()));
    }

    static signIn = (code: string, update: OperationUpdate<string>): void => {
        update(Operation.load());

        httpsCallable(getFunctions(), 'getUserID')({ code })
            .then(result => {
                const ID = result.data as string | null;

                if (ID) {
                    update(Operation.succeed(ID));
                } else {
                    update(Operation.fail(OperationError.userDoesNotExist));
                }
            })
            .catch(() => update(Operation.fail()));
    }

    static observe = (ID: string, update: OperationUpdate<User>): Unsubscribe => {
        return onSnapshot(
            doc(User.collectionReference, ID),
            { includeMetadataChanges: true },
            documentSnapshot => {
                if (!documentSnapshot.metadata.fromCache) {
                    if (documentSnapshot.exists()) {
                        const user = new User(documentSnapshot);

                        update(Operation.succeed(user));
                    } else {
                        update(Operation.fail(OperationError.userDoesNotExist));
                    }
                }
            },
            () => update(Operation.fail())
        );
    }

    static setID = (ID: string | null): void => {
        if (ID) {
            LocalStorage.setValue(User.IDStorageKey, ID);
        } else {
            LocalStorage.deleteValue(User.IDStorageKey);
        }
    }


    ID: string;
    firstName: string;
    lastName: string;
    code: string;
    email: string;
    phone: string;
    company: string;
    workTitle: string;
    workEmail: string;
    country: string;
    city: string;
    address: string;
    partner: string;
    beginner: boolean | null;
    tests: number;

    constructor(documentSnapshot: DocumentSnapshot) {
        const data = documentSnapshot.data() as UserMap;

        this.ID = documentSnapshot.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.code = data.code;
        this.email = data.email;
        this.phone = data.phone;
        this.company = data.company;
        this.workTitle = data.workTitle;
        this.workEmail = data.workEmail;
        this.country = data.country;
        this.city = data.city;
        this.address = data.address;
        this.partner = data.partner;
        this.beginner = data.beginner;
        this.tests = data.tests;
    }


    get documentReference(): DocumentReference {
        return doc(User.collectionReference, this.ID);
    }
    get onboarded(): boolean {
        return this.beginner !== null;
    }

    onboard = (beginner: boolean, update: OperationUpdate): void => {
        update(Operation.load());

        updateDoc(this.documentReference, { beginner })
            .then(() => update(Operation.succeed()))
            .catch(() => update(Operation.fail()));
    }

}


export default User;
export type { UserMap };