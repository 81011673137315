import { Typography, Collapse } from '@mui/material';

import Operation from '../../model/Operation';


interface FormOperationParameters<T> {
    alignment?: 'center' | 'right';
    operation: Operation<T>;
    successMessage?: string;
}


function FormOperation<T>({ alignment, operation, successMessage }: FormOperationParameters<T>) {
    let message;

    if (operation.succeeded && successMessage) {
        message = successMessage;
    } else if (operation.failed) {
        message = operation.errorMessage;
    }

    return (
        <Collapse in={!!message}>
            <Typography
                variant="body2"
                align={alignment || 'center'}
                color="text.secondary"
                sx={{ marginTop: 1 }}>
                {message}
            </Typography>
        </Collapse>
    );
}


export default FormOperation;
export type { FormOperationParameters };