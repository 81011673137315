import { TableContainer, Table as MUITable, TableHead, TableBody, TableRow, TableCell, useTheme, alpha } from '@mui/material';

import Result from '../../model/Result';


interface TableParameters {
    results: Result[];
    focusResult: (result: Result) => void;
}


function Table({ results, focusResult }: TableParameters) {
    const theme = useTheme();
    const columns = [
        {
            key: 'firstName',
            name: 'Nombre'
        },
        {
            key: 'lastName',
            name: 'Apellido'
        },
        {
            key: 'code',
            name: 'Código'
        },
        {
            key: 'email',
            name: 'Correo'
        },
        {
            key: 'phone',
            name: 'Teléfono'
        },
        {
            key: 'company',
            name: 'Empresa'
        },
        {
            key: 'workTitle',
            name: 'Cargo'
        },
        {
            key: 'workEmail',
            name: 'Correo Corporativo'
        },
        {
            key: 'country',
            name: 'País'
        },
        {
            key: 'city',
            name: 'Ciudad'
        },
        {
            key: 'address',
            name: 'Dirección'
        },
        {
            key: 'partnerName',
            name: 'Ejecutivo',

        },
        {
            key: 'duration',
            name: 'Duración'
        },
        {
            key: 'submissionDate',
            name: 'Fecha'
        }
    ];

    return (
        <TableContainer sx={{
            height: 'calc(100vh - 72px)',
            background: 'white',
            borderRadius: 1,
            boxShadow: 1
        }}>
            <MUITable stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                key={column.key}
                                sx={{
                                    paddingX: 3,
                                    whiteSpace: 'nowrap',
                                    color: theme.palette.text.primary,
                                    background: theme.palette.action.disabledBackground
                                }}>
                                {column.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {results.map(result => (
                        <TableRow
                            hover
                            key={result.ID}
                            onClick={() => focusResult(result)}
                            sx={{
                                '&.MuiTableRow-hover:hover': {
                                    cursor: 'pointer',
                                    background: alpha(theme.palette.action.disabledBackground, 0.5)
                                }
                            }}>
                            {columns.map(column => (
                                <TableCell
                                    key={column.key}
                                    sx={{
                                        paddingX: 3,
                                        whiteSpace: 'nowrap',
                                        borderBottom: `1px solid ${theme.palette.divider}`
                                    }}>
                                    {result[column.key as keyof Result] || '—'}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    );
}


export default Table;
export type { TableParameters };