import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import User from '../../../model/User';
import Operation from '../../../model/Operation';

import Level from './Level';
import FormOperation from '../../other/FormOperation';


interface OnboardingParameters {
    user: User;
}


function Onboarding({ user }: OnboardingParameters) {
    const [ beginner, setBeginner ] = useState<boolean | null>(null);
    const [ operation, setOperation ] = useState(Operation.idle());

    const onboard = () => {
        user.onboard(beginner!, setOperation);
    }

    return (
        <Stack
            spacing={6}
            sx={{
                width: '100%',
                maxWidth: { xs: 400, md: 600 }
            }}>
            <Typography variant="h2" align="center">
                Ahora, tienes dos opciones.
            </Typography>

            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={1}>
                <Level
                    title="Soy nivel 1"
                    description="Estoy seguro que soy principiante y no debo tomar el examen de clasificación."
                    selected={beginner === true}
                    select={() => setBeginner(true)}
                />
                <Level
                    title="No se mi nivel"
                    description="Debo tomar el examen de clasificación para evaluar mi nivel."
                    selected={beginner === false}
                    select={() => setBeginner(false)}
                />
            </Stack>

            <Box sx={{
                alignSelf: 'center',
                width: '100%',
                maxWidth: 300
            }}>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={onboard}
                    loading={operation.loading}
                    disabled={beginner === null}>
                    Seleccionar
                </LoadingButton>
                <FormOperation operation={operation} />
            </Box>
        </Stack>
    );
}


export default Onboarding;
export type { OnboardingParameters };