import { Stack, Typography, ButtonBase, useTheme, alpha } from '@mui/material';


interface LevelParameters {
    title: string;
    description: string;
    selected: boolean;
    select: () => void;
}


function Level({ title, description, selected, select }: LevelParameters) {
    const theme = useTheme();

    return (
        <ButtonBase
            onClick={select}
            sx={{
                width: '100%',
                display: 'block',
                textAlign: { xs: 'left', md: 'center' },
                color: theme.palette.primary.main,
                background: selected ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
                border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.divider}`,
                borderRadius: 1,
                transition: theme.transitions.create([ 'background', 'border' ]),
                '&:hover': {
                    background: alpha(theme.palette.primary.main, selected ? 0.08 : 0.04),
                    border: `1px solid ${alpha(theme.palette.primary.main, selected ? 1 : 0.5)}`
                }
            }}>
            <Stack spacing={1.5} sx={{ padding: 3 }}>
                <Typography variant="h4" color="text.primary">
                    {title}
                </Typography>
                <Typography color="text.secondary">
                    {description}
                </Typography>
            </Stack>
        </ButtonBase>
    );
}


export default Level;
export type { LevelParameters };