import { useState, useEffect } from 'react';
import { Box, TextField, InputAdornment, CircularProgress, useTheme, alpha } from '@mui/material';
import { Search } from '@mui/icons-material';

import Result from '../../model/Result';
import Operation from '../../model/Operation';


interface SearchFieldParameters {
    query: string;
    setQuery: (query: string) => void;
    operation: Operation<Result[] | null>;
}


function SearchField({ query, setQuery, operation }: SearchFieldParameters) {
    const [ value, setValue ] = useState(query);
    const theme = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(value);
        }, 500);

        return () => clearTimeout(timer);
    }, [ value ]);

    return (
        <TextField
            fullWidth={false}
            variant="outlined"
            size="small"
            placeholder="Buscar"
            value={value}
            onChange={event => setValue(event.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {operation.loading ? (
                            <Box sx={{ width: 24, height: 24, padding: '3px' }}>
                                <CircularProgress size={18} thickness={4} sx={{ color: 'white' }} />
                            </Box>
                        ) : (
                            <Search sx={{ color: 'white' }} />
                        )}
                    </InputAdornment>
                ),
                sx: {
                    color: 'white',
                    background: alpha('#FFFFFF', 0.08)
                }
            }}
            sx={{
                width: 256,
                '& .MuiInputBase-root fieldset': {
                    border: `1px solid ${alpha('#FFFFFF', 0.24)}`,
                    transition: theme.transitions.create('border'),
                },
                '& .MuiInputBase-root:hover fieldset': {
                    border: `1px solid ${alpha('#FFFFFF', 0.64)}`
                },
                '& .MuiInputBase-root.Mui-focused fieldset': {
                    border: `1px solid ${alpha('#FFFFFF', 0.64)}`
                }
            }}
        />
    );
}


export default SearchField;
export type { SearchFieldParameters };